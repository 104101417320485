import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navigation from './Navigation';
import { AuthContext } from './AuthContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const People = () => {
  const { id } = useParams();  // ID of the selected individual
  const [individual, setIndividual] = useState(null);  // Details of the selected individual
  const [filteredIndividuals, setFilteredIndividuals] = useState([]);  // Filtered list of individuals
  const [individuals, setIndividuals] = useState([]);  // List of all individuals
  const [reportData, setReportData] = useState([]);  // Reports related to the selected individual
  const navigate = useNavigate();
  const { selectedBuilding } = useContext(AuthContext); // Use selectedBuilding from AuthContext

  // Fetch individual data and their reports
  const fetchIndividualData = (individualId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    fetch(`/api/individuals/${individualId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => setIndividual(data))
      .catch(error => console.error('Error fetching individual:', error));

    fetch(`/api/reports?individual_id=${individualId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => setReportData(data))
      .catch(error => console.error('Error fetching report data:', error));
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    fetch('/api/individuals', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => setIndividuals(Array.isArray(data) ? data : []))
      .catch(error => console.error('Error fetching individuals:', error));

    if (id) {
      fetchIndividualData(id);
    }
  }, [id]);

  useEffect(() => {
    if (selectedBuilding) {
      if (selectedBuilding.name === 'All') {
        setFilteredIndividuals(individuals);
      } else {
        const filtered = individuals.filter(
          (individual) => individual.building_location_id === selectedBuilding.id
        );
        setFilteredIndividuals(filtered);
      }
    }
  }, [selectedBuilding, individuals]);

  // Handle changes in radio buttons locally
  const handleCollectedChange = (reportId, newValue) => {
    setReportData(prevReports => 
      prevReports.map(report =>
        report.id === reportId ? { ...report, collected: newValue } : report
      )
    );
  };

  // Handle individual selection from the dropdown
  const handleIndividualSelect = (event) => {
    const individualId = event.target.value;
    navigate(`/people/${individualId}`);
  };

  // Handle date change using DatePicker
  const handleDateChange = (date, reportId) => {
    setReportData(prevReports => 
      prevReports.map(report =>
        report.id === reportId ? { ...report, report_date: date.toISOString() } : report
      )
    );
  };

  // Update the report in the database only when the update button is clicked
  const handleUpdate = (reportId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    const report = reportData.find(r => r.id === reportId);
    const updatedData = {
      collected: report.collected, // Send the updated collected value
      report_date: report.report_date, // Send the updated date
    };

    fetch(`/api/reports/${reportId}`, { 
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update data');
        }
        return response.json();
      })
      .then(data => {
        console.log('Update successful:', data);
        fetchIndividualData(id); // Refresh the data after the update
      })
      .catch(error => console.error('Error updating data:', error));
  };

  // Calculate the number of days between two dates
  const calculateDaysBetween = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000; // Hours*minutes*seconds*milliseconds
    return Math.round(Math.abs((date1 - date2) / oneDay));
  };

  const getBackgroundColor = (reportDate) => {
    const today = new Date();
    const reportDueDate = new Date(reportDate);
    const daysDifference = calculateDaysBetween(today, reportDueDate);

    if (daysDifference >= 90) {
      return 'rgb(245 95 95 / 65%)'; // Red for expired (90+ days)
    } else if (daysDifference >= 60) {
      return 'rgb(224 224 0)'; // Yellow for approaching expiration (60-89 days)
    } else if (daysDifference >= 45) {
      return 'lightblue'; // Blue for passed 45 days (45-59 days)
    } else {
      return 'none'; // No color before 45 days
    }
  };

  if (!individual) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navigation />
      <div className="dashboard-grid">
        <div className="grid-item individual-select">
          <select id="individual-select" onChange={handleIndividualSelect} className="individual-dropdown">
            <option value="">Choose Individual</option>
            {filteredIndividuals.map(individual => (
              <option key={individual.id} value={individual.id}>
                {individual.name}
              </option>
            ))}
          </select>
        
          <div className="people-container grid-item">
            <h2>{individual.name}</h2>
            <p>Case Manager: {individual.case_manager}</p>
            <p>Case Manager Email: {individual.case_manager_email}</p>
            <p>Case Manager Phone: {individual.case_manager_phone_number}</p>
            <p>Building Location: {individual.building_location_name}</p>
          </div>
        </div>
        <div className="individual-data-status grid-item">
          Employee Overall Status
        </div>
        <div className="individual-data-notes grid-item">
          Employee Overall Notes
        </div>
        {reportData.map((report) => {
          const backgroundColor = getBackgroundColor(report.report_date);
          const reportDate = new Date(report.report_date);
          return (
            <div 
              key={report.id} 
              className="individual-data grid-item" 
              style={{ borderLeft: `5px solid ${backgroundColor}` }}
            >
              <div className="form-name">
                <label>{/*Title for forms here*/}</label>
                <div>{report.report_name}</div>
              </div>
              <div className="collected">
                <label>Collected</label>
                <div>
                  <input 
                    type="radio" 
                    name={`collected_${report.id}`} 
                    value="1" 
                    checked={report.collected === 1} 
                    onChange={() => handleCollectedChange(report.id, 1)} 
                  /> Yes
                  <input 
                    type="radio" 
                    name={`collected_${report.id}`} 
                    value="0" 
                    checked={report.collected === 0} 
                    onChange={() => handleCollectedChange(report.id, 0)} 
                  /> No
                </div>
              </div>
              <div className="date" >
                <label>Date</label>
                <DatePicker style={{textAlign:'center'}}
                  selected={reportDate}
                  onChange={(date) => handleDateChange(date, report.id)}
                  dateFormat="MM/dd/yyyy"
                />
              </div>
              <div className="status" style={{border: `2px double ${backgroundColor}`}}>
                <label>Status</label>
                <div>{report.status}</div>
              </div>
              <div className="notes">
                <label>Notes</label>
                <button>View Notes</button>
              </div>
              <div className="update">
                <label></label>
                <button onClick={() => handleUpdate(report.id)}>Update</button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default People;
