import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './Dashboard.css';
import Navigation from './Navigation';

const Dashboard = () => {
  const [locations, setLocations] = useState([]);
  const [filteredIndividuals, setFilteredIndividuals] = useState([]);
  const { selectedBuilding } = useContext(AuthContext);
  const [individuals, setIndividuals] = useState([]);
  const [reportData, setReportData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    fetch('/api/locations', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLocations(Array.isArray(data) ? data : []);
      })
      .catch((error) => console.error('Error fetching locations:', error));

    fetch('/api/individuals', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIndividuals(Array.isArray(data) ? data : []);
      })
      .catch((error) => console.error('Error fetching individuals:', error));

    fetch('/api/reports/upcoming', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setReportData(Array.isArray(data) ? data : []);
      })
      .catch((error) => console.error('Error fetching report data:', error));
  }, []);

  useEffect(() => {
    if (selectedBuilding) {
      if (selectedBuilding.name === 'All') {
        setFilteredIndividuals(individuals);
      } else {
        const filtered = individuals.filter(
          (individual) => individual.building_location_id === selectedBuilding.id
        );
        setFilteredIndividuals(filtered);
      }
    }
  }, [selectedBuilding, individuals]);

  const handleIndividualSelect = (event) => {
    const individualId = event.target.value;
    navigate(`/people/${individualId}`);
  };

  const calculateDaysBetween = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((date1 - date2) / oneDay));
  };

  const getBackgroundColor = (daysSinceReport) => {
    if (daysSinceReport >= 90) {
      return 'rgb(245 95 95 / 65%)'; // Red for expired (90+ days)
    } else if (daysSinceReport >= 60) {
      return 'rgb(224 224 0)'; // Yellow for approaching expiration (60-89 days)
    } else if (daysSinceReport >= 45) {
      return 'lightblue'; // Blue for passed 45 days (45-59 days)
    } else {
      return 'none'; // No color before 45 days
    }
  };

  return (
    <>
      <Navigation />
      <div className="dashboard-grid">
        <div className="legend">
          <div className="legend-item">
            <span className="legend-color legend-yellow"></span>
            <span className="legend-text">30 + days before form expiration</span>
          </div>
          <div className="legend-item">
            <span className="legend-color legend-lightblue"></span>
            <span className="legend-text">45 + days since form submission</span>
          </div>
          <div className="legend-item">
            <span className="legend-color legend-red"></span>
            <span className="legend-text">90 + Days since form submission</span>
          </div>
        </div>
        <div className="grid-item individual-select">
          <select id="individual-select" onChange={handleIndividualSelect} className="individual-dropdown">
            <option value="">Choose Individual</option>
            {filteredIndividuals.map((individual) => (
              <option key={individual.id} value={individual.id}>
                {individual.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <div className="expiring-title">All Forms Status Based on Report Date</div>
          <div className="report-list grid-item">
            {reportData.map((report) => {
              const daysSinceReport = report.days_since_report;
              const backgroundColor = getBackgroundColor(daysSinceReport);
              return (
                <Link key={report.id} to={`/people/${report.individual_id}`} className="report-item-link">
                  <div 
                    className="report-item" 
                    style={{ borderLeft: `5px solid ${backgroundColor}` }}
                  >
                    <div className="report-info">
                      <p><i className="fas fa-user"></i> {report.individual_name}</p>
                      <p><i className="fas fa-file-medical-alt"></i> {report.report_name}</p>
                      <p><i className="fas fa-map-marker-alt"></i> {report.building_location_name}</p>
                      <p><i className="fas fa-calendar-alt"></i> {new Date(report.report_date).toLocaleDateString()}</p>
                      <p><i className="fas fa-clock"></i> Added {daysSinceReport} days ago</p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
