import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import Home from './components/Home';
import Setup2FA from './components/Setup2FA';
import Logout from './components/Logout';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard';
import UpdateProfile from './components/UpdateProfile';
import Navigation from './components/Navigation';
import People from './components/People';
import { AuthProvider, AuthContext } from './components/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <NavigationWrapper />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/setup-2fa" element={<Setup2FA />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/home" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/people/:id" element={<People />} />
              <Route path="/update-profile" element={<UpdateProfile />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

// This component wraps the Navigation with authentication checks
const NavigationWrapper = () => {
  const { isAuthenticated, selectedBuilding } = useContext(AuthContext);

  return isAuthenticated ? (
    <Navigation building={selectedBuilding} />
  ) : null;
};

export default App;
