import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './Navigation.css';

const Navigation = () => {
  const [buildings, setBuildings] = useState([]); // State to hold the list of buildings
  const [individuals, setIndividuals] = useState([]);
  const { selectedBuilding, setSelectedBuilding } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch buildings from your server
    const fetchBuildings = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming you need a token for the API call
        const response = await fetch('/api/locations', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setBuildings(data);
        } else {
          console.error('Failed to fetch buildings:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching buildings:', error);
      }
    };

    const fetchIndividuals = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found');
          return;
        }

        const response = await fetch('/api/individuals', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setIndividuals(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching individuals:', error);
      }
    };

    fetchBuildings();
    fetchIndividuals();
  }, []);

  const handleBuildingChange = (event) => {
    const buildingId = event.target.value;
    const building = buildings.find((b) => b.id === parseInt(buildingId, 10));
    setSelectedBuilding(building);
  };

  const handleIndividualSelect = (event) => {
    const individualId = event.target.value;
    navigate(`/people/${individualId}`);
  };

  return (
    <nav className="navigation-bar">
      
      <div className="nav-left">
        <h1>FORMS</h1>
      </div>
      
      <div className="nav-right">
        <select onChange={handleBuildingChange} value={selectedBuilding?.id || ''} className="location-dropdown">
          <option value="" disabled>Select a building</option>
          {buildings.map((building) => (
            <option key={building.id} value={building.id}>
              {building.name}
            </option>
          ))}
        </select>
       
        <div className="nav-links">
          <Link to="/home">Home</Link>
          <Link to="/setup-2fa">Setup 2FA</Link>
          <Link to="/onboard">Add user</Link>
          <Link to="/dashboard">Dashboard</Link>
          <Link to="/logout">Logout</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
