import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import seabirdOfficePhoto from './../images/office_bird.png';
import { AuthContext } from '../components/AuthContext'; // Ensure correct path
import './Login.css';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001/api'; // Ensure API URL is correct

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [twoFactorRequired, setTwoFactorRequired] = useState(false);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext); // Use context to update auth state

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      alert('Please provide both username and password.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.twoFactorRequired) {
        setTwoFactorRequired(true);
        setUserId(data.userId);
      } else if (data.token) {
        localStorage.setItem('token', data.token);
        setIsAuthenticated(true); // Update auth status
        console.log('Login successful!');
        navigate('/dashboard'); // Redirect to home page
      } else {
        alert('Login failed: ' + data.message);
      }
    } catch (error) {
      console.error('There was an error with the login request:', error);
      alert('There was an error processing your request. Please try again later.');
    }
  };

  const handle2FASubmit = async (event) => {
    event.preventDefault();

    if (!token) {
      alert('Please provide the 2FA token.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/verify-2fa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, token }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.token) {
        localStorage.setItem('token', data.token);
        setIsAuthenticated(true); // Update auth status
        alert('2FA verification successful!');
        navigate('/home');
      } else {
        alert('2FA verification failed: ' + data.message);
      }
    } catch (error) {
      console.error('There was an error with the 2FA verification request:', error);
      alert('There was an error processing your request. Please try again later.');
    }
  };

  return (
    <div className="login-container">
      {!twoFactorRequired ? (
        <form className="login__form" onSubmit={handleLoginSubmit}>
          <img src={seabirdOfficePhoto} alt="Seabird Office" className="login__photo" />
          <label htmlFor="username" className="login__label"></label>
          <input placeholder="Username" type="text" id="username" className="login__input" value={username} onChange={(e) => setUsername(e.target.value)} required />
          <label htmlFor="password" className="login__label"></label>
          <input placeholder="password" type="password" id="password" className="login__input" value={password} onChange={(e) => setPassword(e.target.value)} required />
          <button type="submit" className="login__button">Login</button>
        </form>
      ) : (
        <form className="login__form" onSubmit={handle2FASubmit}>
          <label htmlFor="token" className="login__label">2FA Token:</label>
          <input type="text" id="token" className="login__input" value={token} onChange={(e) => setToken(e.target.value)} required />
          <button type="submit" className="login__button">Verify 2FA</button>
        </form>
      )}
    </div>
  );
};

export default Login;
